import { ElementType, forwardRef } from 'react'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'

import useFirebase from '../hooks/useFirebase'

import Text from './Text'

type LogoProps = {
  link?: boolean
  giant?: boolean
  as?: ElementType
  align?: string
}

const Logo = forwardRef<HTMLParagraphElement, LogoProps>(
  ({ link = false, giant = false, as = 'h1', align }: LogoProps, ref) => {
    // TODO: remove next line when we want to show landing page
    const { isAuthenticated } = useFirebase()

    const { t } = useTranslation('common')
    const text = t('jobber').toUpperCase()

    return (
      <Text
        ref={ref}
        as={as}
        weight="bold"
        size={giant ? 'giant' : 'large'}
        align={align}
      >
        {link ? (
          // TODO: update href on next line to '/' when we want to show landing page
          <Link href={isAuthenticated ? '/profile' : '/auth'}>
            <a>{text}</a>
          </Link>
        ) : (
          text
        )}
      </Text>
    )
  },
)

export default Logo
