import { Text } from '@jobber-project/jobber-ui'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import React from 'react'
import styled from 'styled-components'

import Column from '../../components/Column'
import Grid from '../../components/Grid'
import { COLORS } from '../../utils/colorUtils'
import { media } from '../../utils/stringUtils'
import { BREAKPOINT } from '../app/constants'
import FacebookIcon from '../../assets/icons/active/facebook.svg'
import LinkedInIcon from '../../assets/icons/active/linkedin.svg'

const Container = styled.footer`
  width: 100%;
  padding: 40px 0 20px;
  box-sizing: border-box;
  color: ${COLORS.white};
  background: linear-gradient(
    90deg,
    ${COLORS.electricViolet} 0%,
    ${COLORS.cornflowerBlue} 100%
  );

  ${media(BREAKPOINT.medium)} {
    padding: 62px 0 30px;
  }
`

const Title = styled(Text)`
  margin-bottom: 1em;
  font-size: 1.8rem;
  line-height: 1.389;
  white-space: nowrap;
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  ${media(BREAKPOINT.medium)} {
    margin-bottom: 90px;
  }

  a:hover {
    text-decoration: underline;
  }
`

const ListItem = styled.li`
  margin-bottom: 11px;
`

const LinkText = styled(Text)`
  font-size: 1.2rem;
  line-height: 1.417em;
`

const Socials = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  ${media(BREAKPOINT.medium)} {
    margin-bottom: 0;
  }

  & > a + a {
    margin-left: 16px;
  }
`

const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 32px;
  background-color: ${COLORS.white};
`

const Credits = styled.div`
  z-index: 0;
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
`

const TextDivider = styled.div`
  flex-shrink: 1;
  flex-grow: 1;
  max-width: 32px;
`

const Version = styled(Text)`
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0.5;
`

function Footer() {
  const { t } = useTranslation('common')

  return (
    <Container>
      <Grid pad>
        <Column md={7}>
          <Grid reset pad>
            <Column md={8}>
              <Grid reset pad>
                {/* <Column sm={4}> */}
                <Column>
                  <Title size="increased" weight="bold">
                    {t('jobber')}
                  </Title>
                  <List>
                    <ListItem>
                      <Link href="/terms-and-conditions">
                        <a>
                          <LinkText forwardedAs="span" size="tiny">
                            {t('termsOfUse')}
                          </LinkText>
                        </a>
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href="/terms-and-conditions/contact">
                        <a>
                          <LinkText forwardedAs="span" size="tiny">
                            {t('contact')}
                          </LinkText>
                        </a>
                      </Link>
                    </ListItem>
                  </List>
                </Column>
                {/* <Column sm={4}>
                  <Title size="increased" weight="bold">
                    {t('product')}
                  </Title>
                </Column>
                <Column sm={4}>
                  <Title size="increased" weight="bold">
                    {t('company')}
                  </Title>
                </Column> */}
              </Grid>
            </Column>
            <Column md={4}>
              <Title size="increased" weight="bold">
                {t('socials')}
              </Title>
              <Socials>
                <a
                  href="https://www.facebook.com/hellojobello/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookIcon />
                </a>
                <a
                  href="https://www.linkedin.com/company/74830595"
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkedInIcon />
                </a>
              </Socials>
            </Column>
          </Grid>
        </Column>
      </Grid>
      <Grid pad>
        <Column>
          <Separator />
          <Credits>
            <Text size="regular">
              &copy; {t('jobelloTechnology')} 2020 &ndash;{' '}
              {new Date().getFullYear()}{' '}
            </Text>
            <TextDivider />
            <Text size="regular">{t('allRightsReserved')}</Text>
            <Version size="micro">
              {`v${process.env.NEXT_PUBLIC_APP_VERSION ?? '0.0.0'}`}
            </Version>
          </Credits>
        </Column>
      </Grid>
    </Container>
  )
}

export default Footer
