import Head from 'next/head'
import Script from 'next/script'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { ANALYTICS_ID } from '../app/constants'

import Navbar from './Navbar'
import Footer from './Footer'

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`

const Content = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
`

const isDevelopment = process.env.NODE_ENV === 'development'
const analyticsId = ANALYTICS_ID[process.env.NEXT_PUBLIC_ENVIRONMENT]

export default function Layout({ showNavbar, showFooter, title, children }) {
  return (
    <Wrapper>
      <Head>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      {!!analyticsId && !isDevelopment && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${analyticsId}`}
          />
          <Script id="google-analytics">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', '${analyticsId}');
            `}
          </Script>
        </>
      )}
      {showNavbar && <Navbar />}
      <Content>{children}</Content>
      {showFooter && <Footer />}
    </Wrapper>
  )
}

Layout.propTypes = {
  showNavbar: PropTypes.bool,
  showFooter: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  showNavbar: true,
  showFooter: true,
  title: '',
}
