import { ReactNode, forwardRef, ElementType } from 'react'
import styled from 'styled-components'

import { GRID_WIDTH, BREAKPOINT } from '../features/app/constants'

import { StyledColumn } from './Column'

const StyledGrid = styled.div<{
  $pad: boolean
  $reset: boolean
  $fluid: boolean
}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  ${props =>
    props.$pad
      ? `
        & > ${StyledColumn} {
          @media (min-width: ${BREAKPOINT.small}px) {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
          }

          @media (min-width: ${BREAKPOINT.medium}px) {
            padding-right: 1rem;
            padding-left: 1rem;
          }

          @media (min-width: ${BREAKPOINT.large}px) {
            padding-right: 1.5rem;
            padding-left: 1.5rem;
          }
        }
        `
      : ''}

  ${props =>
    props.$reset
      ? `
        & {
          width: auto;

          @media (min-width: ${BREAKPOINT.small}px) {
            margin-right: -0.5rem;
            margin-left: -0.5rem;
          }

          @media (min-width: ${BREAKPOINT.medium}px) {
            margin-right: -1rem;
            margin-left: -1rem;
          }

          @media (min-width: ${BREAKPOINT.large}px) {
            margin-right: -1.5rem;
            margin-left: -1.5rem;
          }
        }
        `
      : ''}

  ${props =>
    props.$fluid
      ? ''
      : `
    max-width: ${GRID_WIDTH}px;
    margin-right: auto;
    margin-left: auto;
  `}
`

type GridProps = {
  as?: ElementType
  pad?: boolean
  fluid?: boolean
  reset?: boolean
  className?: string
  children?: ReactNode
}

const Grid = forwardRef(
  (
    {
      as = undefined,
      pad = false,
      fluid = false,
      reset = false,
      className = undefined,
      children = null,
    }: GridProps,
    ref,
  ) => {
    return (
      <StyledGrid
        ref={ref}
        as={as}
        $pad={pad}
        $fluid={fluid}
        $reset={reset}
        className={className}
      >
        {children}
      </StyledGrid>
    )
  },
)

export default Grid
