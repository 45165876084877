import { useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'

import { COLORS } from '../utils/colorUtils'

const WIDTHS = [100, 80, 90, 75, 95, 60, 100]

const Wrapper = styled.span<{
  $width: string
  $height: string
  $minWidth: string
  $maxWidth: string
}>`
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${props => props.$width};
  height: ${props => props.$height};
  min-width: ${props => props.$minWidth};
  max-width: ${props => props.$maxWidth};
`

const Background = styled.span<{
  $index: number
}>`
  width: ${props => WIDTHS[props.$index] ?? 100}%;
  height: 100%;
  background-color: ${COLORS.secondary};

  & + & {
    margin-top: 0.5em;
  }
`

const Text = styled.span`
  z-index: 1;
  position: absolute;
  color: transparent;
  user-select: none;
`

type SkeletonProps = {
  numberOfLines?: number
  width?: string
  height?: string
  minWidth?: string
  maxWidth?: string
}

function Skeleton({
  numberOfLines = 1,
  width = '100%',
  height = '100%',
  minWidth = '0',
  maxWidth = 'none',
}: SkeletonProps) {
  const { t } = useTranslation('common')

  const array = useMemo(() => {
    if (numberOfLines > 1) {
      return Array.from({ length: numberOfLines }).map((_, i) => i)
    }

    return [0]
  }, [numberOfLines])

  return (
    <Wrapper
      $width={width}
      $height={height}
      $minWidth={minWidth}
      $maxWidth={maxWidth}
    >
      <Text>{t('loading')}...</Text>
      {array.map(i => (
        <Background key={i} $index={i} />
      ))}
    </Wrapper>
  )
}

export default Skeleton
