/* eslint-disable react/jsx-props-no-spreading */
import { ReactNode, forwardRef } from 'react'
import styled from 'styled-components'

import { COLORS } from '../utils/colorUtils'

import Spinner from './Spinner'

const StyledButton = styled.button<{
  alignIconEnd: boolean
  fluid: boolean
  disabled: boolean
}>`
  z-index: 1;
  position: relative;
  appearance: none;
  display: inline-flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: ${props => (props.alignIconEnd ? 'row-reverse' : 'row')};
  width: ${props => (props.fluid ? '100%' : 'auto')};
  padding: 0;
  box-sizing: border-box;
  border-radius: 5px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  text-align: center;
  font-size: 1.8rem;
  line-height: 1.2em;
  vertical-align: top;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  user-select: none;
`

const PrimaryButton = styled(StyledButton)<{
  inverted: boolean
}>`
  color: ${props => (props.inverted ? COLORS.primary : COLORS.white)};
  background-color: ${props =>
    props.inverted ? COLORS.white : COLORS.primary};
  border: 1px solid ${props => (props.inverted ? COLORS.white : COLORS.primary)};
`

const SecondaryButton = styled(StyledButton)`
  color: ${COLORS.gray};
  border: 1px solid ${COLORS.gray};
  background-color: transparent;
`

const IconWrapper = styled.span<{
  $plain: boolean
  alignIconEnd: boolean
  large: boolean
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${props =>
    !props.$plain &&
    `
    padding-${props.alignIconEnd ? 'right' : 'left'}: ${
      props.large ? '12px' : '8px'
    };
  `}
`

const Inner = styled.span<{
  large: boolean
  icon: boolean
  alignIconEnd: boolean
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${props => (props.large ? '18px 24px' : '8px 16px')};
  ${props =>
    props.icon
      ? `
    padding-${props.alignIconEnd ? 'right' : 'left'}: ${
          props.large ? '12px' : '8px'
        };
  `
      : ''}
`

const PlainButton = styled.button<{
  disabled: boolean
}>`
  appearance: none;
  display: inline-flex;
  padding: 0;
  border-radius: 0;
  border: none;
  background-color: transparent;
  font-size: 1.8rem;
  line-height: 1.2em;
  color: inherit;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`

const Content = styled.span<{
  $hidden: boolean
}>`
  display: inline-flex;
  visibility: ${props => props.$hidden && 'hidden'};
`

const StyledSpinner = styled(Spinner)`
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;

  & span,
  & span:after {
    padding: 5px;
    width: 100%;
    height: 100%;
    max-width: 10em;
    max-height: 10em;
    box-sizing: border-box;
  }
`

function Fragment({ children }) {
  return children
}

type ButtonProps = {
  primary?: boolean
  secondary?: boolean
  plain?: boolean
  large?: boolean
  fluid?: boolean
  inverted?: boolean
  alignIconEnd?: boolean
  loading?: boolean
  disabled?: boolean
  as?: string
  id?: string
  type?: string
  ariaLabel?: string
  icon?: ReactNode
  children: ReactNode
  onClick?: Function
}

const Button = forwardRef(
  (
    {
      primary = true,
      secondary = false,
      plain = false,
      large = false,
      fluid = false,
      inverted = false,
      alignIconEnd = false,
      loading = false,
      disabled = false,
      as,
      id,
      type,
      ariaLabel,
      icon = null,
      children,
      ...props
    }: ButtonProps,
    ref,
  ) => {
    const Component = (() => {
      if (plain) return PlainButton
      if (secondary) return SecondaryButton
      if (primary) return PrimaryButton
      return PrimaryButton
    })()

    const ContentComponent = loading ? Content : Fragment
    const InnerComponent = plain ? Fragment : Inner

    return (
      // @ts-ignore
      <Component
        ref={ref}
        fluid={fluid}
        inverted={inverted}
        alignIconEnd={alignIconEnd}
        disabled={disabled || loading}
        as={as}
        id={id}
        type={type}
        aria-label={ariaLabel}
        {...props}
      >
        <ContentComponent $hidden={loading}>
          {!!icon && (
            <IconWrapper
              $plain={plain}
              large={large}
              alignIconEnd={alignIconEnd}
            >
              {icon}
            </IconWrapper>
          )}
          <InnerComponent
            large={large}
            icon={!!icon}
            // @ts-ignore
            alignIconEnd={alignIconEnd}
          >
            {children}
          </InnerComponent>
        </ContentComponent>
        {loading && <StyledSpinner color={inverted ? 'black' : 'white'} />}
      </Component>
    )
  },
)

export default Button
