import { onAuthStateChanged } from 'firebase/auth'
import { useState, useEffect } from 'react'

import { auth } from '../features/firebase'

export default function useFirebase() {
  const [isInitializing, setIsInitializing] = useState(!auth.currentUser)
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!auth.currentUser && !auth.currentUser.isAnonymous,
  )

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, () => {
      setIsAuthenticated(!!auth.currentUser)
      setIsInitializing(false)
    })

    return unsubscribe
  }, [])

  return { isInitializing, isAuthenticated }
}
