import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'
import { Button } from '@jobber-project/jobber-ui'

import useFirebase from '../../hooks/useFirebase'
import Column from '../../components/Column'
import Grid from '../../components/Grid'
import Logo from '../../components/Logo'
import { COLORS, rgba } from '../../utils/colorUtils'
import ProfileDropdown from '../profile/ProfileDropdown'
import Text from '../../components/Text'
import { PROFILE_ROLE_QUERY } from '../profile/profileQueries'
import { ROLE_TYPE } from '../app/constants'

const Container = styled.header`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  width: 100%;
  height: 64px;
  background-color: ${COLORS.white};
  border-bottom: 1px solid ${COLORS.mischa};
  color: ${COLORS.charade};

  & a {
    display: flex;
    align-items: center;
    height: 100%;
  }
`

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  width: 100%;
`

const Left = styled.div`
  display: flex;
`

const Nav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: 24px;
`

const List = styled.ul`
  display: flex;
  flex-direction: row;
  gap: 16px;
`

const ListItem = styled.li`
  display: flex;
  margin: 0 8x;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`

const BetaTag = styled.div`
  display: flex;
  align-items: center;
  padding: 0 4px;
  border-radius: 4px;
  background-color: ${rgba(COLORS.emerald, 0.25)};
  margin-left: 4px;
`

const BetaTagText = styled(Text)`
  font-size: 1.2rem;
  line-height: 1.584em;
  font-weight: 500;
  text-transform: uppercase;
`

const LinkText = styled(Text)`
  font-size: 1.8rem;
  line-height: 1.167em;
  font-weight: 500;
`

export default function Navbar() {
  const { t } = useTranslation('common')
  const { isAuthenticated } = useFirebase()
  const { data } = useQuery(PROFILE_ROLE_QUERY)

  return (
    <Container>
      <Grid pad>
        <Column>
          <Row>
            <Left>
              <Logo link />
            </Left>
            <Nav>
              <List>
                {isAuthenticated && (
                  <ListItem>
                    {/* <JobsNavigationDropdown /> */}
                    <LinkText forwardedAs="span" size="increased">
                      <Link href="/jobs">
                        <a>
                          Jobb
                          <BetaTag>
                            <BetaTagText>Beta</BetaTagText>
                          </BetaTag>
                        </a>
                      </Link>
                    </LinkText>
                  </ListItem>
                )}
                {isAuthenticated &&
                  data?.getUser?.roles?.type === ROLE_TYPE.coach && (
                    <ListItem>
                      <LinkText forwardedAs="span" size="increased">
                        <Link href="/coach">
                          <a>Coach</a>
                        </Link>
                      </LinkText>
                    </ListItem>
                  )}
              </List>
            </Nav>
            <Right>
              {isAuthenticated ? (
                <ProfileDropdown />
              ) : (
                <>
                  <Link passHref href="/auth">
                    <Button as="a">{t('login')}</Button>
                  </Link>
                  <Link passHref href="/auth/register">
                    <Button as="a" variant="primary">
                      {t('register')}
                    </Button>
                  </Link>
                </>
              )}
            </Right>
          </Row>
        </Column>
      </Grid>
    </Container>
  )
}
