import { useRef, useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'next-i18next'
import Router, { useRouter } from 'next/router'
import styled from 'styled-components'
import { Avatar, Menu } from '@jobber-project/jobber-ui'
import { signOut as firebaseSignOut } from 'firebase/auth'

import { auth } from '../firebase'
import Skeleton from '../../components/Skeleton'
import Button from '../../components/Button'
import Text from '../../components/Text'
import { COLORS } from '../../utils/colorUtils'
import ChevronUp from '../../assets/icons/active/chevron-up.svg'
import ChevronDown from '../../assets/icons/active/chevron-down.svg'
import User from '../../assets/icons/active/user.svg'
import Settings from '../../assets/icons/active/settings.svg'
import SignOut from '../../assets/icons/active/log-out.svg'
import { media } from '../../utils/stringUtils'
import { BREAKPOINT } from '../app/constants'

import { PROFILE_QUERY } from './profileQueries'
import { clearSelectedCoverLetterIdVar } from './profileVars'

const Container = styled.div`
  z-index: 2;
  position: relative;
  height: 64px;
  overflow: visible;
  padding-left: 24px;
`

const VerticalLine = styled.div`
  z-index: 1;
  position: absolute;
  top: 15px;
  bottom: 15px;
  left: -1px;
  width: 1px;
  background-color: ${COLORS.mischa};
`

const Toggle = styled(Button).attrs({
  plain: true,
})`
  z-index: 2;
  position: relative;
  align-items: center;
  height: 100%;
  background-color: ${COLORS.white};

  & svg {
    pointer-events: none;
  }
`

const Caption = styled(Text)`
  font-size: 1.8rem;
  line-height: 1.167em;
  font-weight: 500;
  padding: 0 48px 0 24px;
`

const DropdownMenu = styled(Menu)`
  & > div {
    top: 5px;
    right: 0;
    left: 0;

    ${media(BREAKPOINT.large)} {
      right: -10px;
      left: -10px;
    }
  }
`

export default function ProfileDropdown() {
  const { t } = useTranslation('common')
  const { client, loading, data } = useQuery(PROFILE_QUERY)
  const router = useRouter()

  const [isOpen, setIsOpen] = useState(false)

  const containerRef = useRef(null)

  function toggle() {
    setIsOpen(!isOpen)
  }

  function signOut() {
    firebaseSignOut(auth).then(() => {
      client.clearStore()
      clearSelectedCoverLetterIdVar()
    })
    Router.push('/')
  }

  useEffect(() => {
    function handleClick({ target }) {
      if (!containerRef.current?.contains?.(target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  return loading ? (
    <Skeleton width="120px" height="30px" />
  ) : (
    <Container ref={containerRef}>
      <VerticalLine />
      <Toggle
        aria-expanded={isOpen}
        aria-haspopup="true"
        aria-controls="profile-menu"
        onClick={toggle}
      >
        <Avatar
          size="small"
          name={[data?.getUser?.firstName, data?.getUser?.lastName].join(' ')}
          id={data?.getUser?.id}
          src={data?.getUser?.avatarUrl}
        />

        <Caption forwardedAs="span" size="increased">
          {[data?.getUser?.firstName, data?.getUser?.lastName].join(' ')}
        </Caption>
        {isOpen ? (
          <ChevronUp width={24} height={24} aria-hidden="true" />
        ) : (
          <ChevronDown width={24} height={24} aria-hidden="true" />
        )}
      </Toggle>
      <DropdownMenu isVisible={isOpen} align="right">
        <Menu.Item
          active={router.route.startsWith('/profile')}
          onClick={() => {
            router.push('/profile')
          }}
          icon={<User width={20} height={20} aria-hidden="true" />}
        >
          {t('profile')}
        </Menu.Item>
        <Menu.Item
          active={router.route.startsWith('/settings')}
          onClick={() => {
            router.push('/settings')
          }}
          icon={<Settings width={20} height={20} aria-hidden="true" />}
        >
          {t('settings')}
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            signOut()
            router.push('/')
          }}
          icon={<SignOut width={20} height={20} aria-hidden="true" />}
        >
          {t('signOut')}
        </Menu.Item>
      </DropdownMenu>
    </Container>
  )
}
