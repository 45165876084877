import PropTypes from 'prop-types'
import styled from 'styled-components'

import { BREAKPOINT, GRID_COLUMNS } from '../features/app/constants'

export const StyledColumn = styled.div`
  box-sizing: border-box;

  @media (min-width: ${BREAKPOINT.small}px) {
    width: ${props => (props.sm / GRID_COLUMNS) * 100}%;
  }

  ${props =>
    props.md
      ? `
    @media (min-width: ${BREAKPOINT.medium}px) {
      width: ${(props.md / GRID_COLUMNS) * 100}%;
    }
  `
      : ''}

  ${props =>
    props.lg
      ? `
    @media (min-width: ${BREAKPOINT.large}px) {
      width: ${(props.lg / GRID_COLUMNS) * 100}%;
    }
  `
      : ''}
`

export default function Column({ as, className, sm, md, lg, style, children }) {
  return (
    <StyledColumn
      as={as}
      className={className}
      sm={sm}
      md={md}
      lg={lg}
      style={style}
    >
      {children}
    </StyledColumn>
  )
}

Column.propTypes = {
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  as: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.node,
}

Column.defaultProps = {
  sm: 12,
  md: undefined,
  lg: undefined,
  as: undefined,
  className: undefined,
  style: undefined,
  children: undefined,
}
