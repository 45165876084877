import { gql } from '@apollo/client'

export const PROFILE_QUERY = gql`
  query getUser($alias: String) {
    getUser(alias: $alias) {
      id
      alias
      firstName
      lastName
      professionalTitle
      description
      address
      city
      zipCode
      email
      phoneNumber
      gender
      dateOfBirth
      progress
      avatarUrl
      sections {
        id
        title
        position
        activated
        type
        content {
          ... on Text {
            id
            activated
            position
            type
            description
            title
          }
          ... on Experience {
            id
            activated
            position
            type
            description
            title
            startDate
            endDate
            organization
            location
          }
          ... on Tag {
            id
            activated
            position
            type
            description
            grade
            title
          }
        }
      }
      coverLetters {
        id
        name
        text
        date
        company
        position
      }
      coverLetterGenerations {
        generationsLeft
        totalGrantedGenerations
        totalUsed
      }
      roles {
        id
        type
        content {
          ... on Candidate {
            coachList {
              id
              firstName
              lastName
              email
              alias
              avatarUrl
            }
          }
          ... on Coach {
            candidateList {
              id
              user {
                avatarUrl
                id
                firstName
                lastName
                email
                alias
                progress
                coverLetters {
                  id
                  name
                }
              }
              status
              startdate
              statusBy {
                firstName
                lastName
              }
            }
            pendingList
          }
        }
      }
      invitations {
        id
        type
        invitedBy {
          id
          email
          firstName
          lastName
        }
        acceptedAt
        subject
        message
        createdAt
        response
      }
    }
  }
`

export const PROFILE_ROLE_QUERY = gql`
  query getUser($alias: String) {
    getUser(alias: $alias) {
      id
      progress
      roles {
        id
        type
        content {
          ... on Candidate {
            coachList {
              id
              firstName
              lastName
              email
              alias
              avatarUrl
            }
          }
          ... on Coach {
            candidateList {
              id
              user {
                id
                firstName
                lastName
                email
                alias
                progress
                avatarUrl
                coverLetters {
                  id
                  name
                }
              }
              status
              startdate
              statusBy {
                firstName
                lastName
              }
            }
            pendingList
          }
        }
      }
    }
  }
`

export const PREVIEW_COVER_LETTERS_QUERY = gql`
  query getUser($alias: String) {
    getUser(alias: $alias) {
      id
      alias
      firstName
      lastName
      address
      city
      zipCode
      email
      phoneNumber
      gender
      dateOfBirth
      avatarUrl
      coverLetters {
        id
        name
        text
        date
        company
        position
      }
    }
  }
`

export const COVER_LETTER_COMPLETION_QUERY = gql`
  query coverLetterCompletion($data: CoverLetterCompletionInput!) {
    coverLetterCompletion(data: $data)
  }
`
