import styled, { keyframes } from 'styled-components'

import { COLORS } from '../utils/colorUtils'

const animation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Container = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  width: 100%;
  height: 100%;
`

const Inner = styled.span<{
  $color: string
}>`
  margin: auto;
  font-size: 2px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.5em solid ${props => props.$color};
  border-right: 1.5em solid ${props => props.$color};
  border-bottom: 1.5em solid ${props => props.$color};
  border-left: 1.5em solid ${COLORS.white};
  transform: translateZ(0);
  animation: ${animation} 1.1s infinite linear;

  &,
  &:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
`

type SpinnerColor = 'white' | 'black'

type SpinnerProps = {
  color?: SpinnerColor
  className?: string
}

export default function Spinner({ color = 'white', className }: SpinnerProps) {
  function getColor(): string {
    switch (color) {
      case 'black':
        return 'rgba(0, 0, 0, 0.2)'

      default:
        return 'rgba(255, 255, 255, 0.2)'
    }
  }

  return (
    <Container className={className}>
      <Inner $color={getColor()} />
    </Container>
  )
}
