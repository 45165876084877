import { i18n } from 'next-i18next'

import { BREAKPOINT, MONTH_ARRAY } from '../features/app/constants'

/**
 * Get a space-seperated name
 * @param firstName First name
 * @param lastName Last name
 * @returns Full name string
 */
export function getNameString(firstName?: string, lastName?: string): string {
  if (lastName) {
    return `${firstName}${firstName ? ' ' : ''}${lastName}`
  }

  if (firstName) {
    return firstName
  }

  return ''
}

/**
 * Get a comma-seperated address
 * @param address Address
 * @param zipCode Zip code
 * @param city City
 * @returns Formatted address
 */
export function getAddressString(
  address?: string,
  zipCode?: string,
  city?: string,
): string {
  let str = address ?? ''

  if (zipCode) {
    str = `${str}${address ? ', ' : ''}${zipCode}`
  }

  if (city && zipCode) {
    str = `${str} ${city}`
  } else if (city) {
    str = `${str}${address ? ', ' : ''}${city}`
  }

  return str
}

/**
 * Get a date range ie "2020 - 2021" or just "2020" if endDate is falsy
 * @param startDate Start date number in UNIX
 * @param endDate End date number in UNIX
 * @param mapperFn Optional map function
 * @returns Date range string
 */
export function getDateRangeString(
  startDate?: number,
  endDate?: number,
  mapperFn = (unix: Date | number) => `${new Date(unix).getFullYear()}`,
): string {
  return [startDate, endDate].filter(Boolean).map(mapperFn).join(' - ')
}

/**
 * Sanitize a string
 * @param str String to sanitize
 * @returns Sanitized string
 */
export function sanitizeString(str?: string): string {
  if (!str) return ''

  const sanitized = str
    .toLowerCase() // Make it lowercase
    .replace(/-/g, 'DASH') // Replace - with DASH
    .replace(/\s/g, 'DASH') // Replace spaces with DASH
    .replace(/[åä]/g, 'a') // Replace å and ä with a
    .replace(/[ö]/g, 'o') // Replace ö with o
    .replace(/\W/g, '') // Remove any remaining non-characters
    .replace(/DASH/g, '-') // Replace DASH with -

  return sanitized
}

/**
 * Get a sanitized and formatted valid filename from a string
 * @param str String to sanitize
 * @param extension Optional extension to add with or without the period sign `.pdf` or `pdf`
 * @returns Sanitized filename
 */
export function getFilenameString(str?: string, extension?: string): string {
  if (!str) return ''

  const sanitized = sanitizeString(str)

  if (extension) {
    return `${sanitized}${extension.includes('.') ? '' : '.'}${extension}`
  }

  return sanitized
}

/**
 * Get a media string with a breakpoint
 * @param breakpoint Breakpoint enum
 * @returns "@media (min-width: `breakpoint`px)"
 */
export function media(breakpoint: BREAKPOINT): string {
  return `@media (min-width: ${breakpoint}px)`
}

/**
 * Format a string to a phone number
 * @param inputValue Input string to format to phone number
 * @returns Formatted phone number string
 */
export function getFormattedPhoneNumber(inputValue: string): string {
  if (inputValue.length < 3) return inputValue

  let nextValue = inputValue

  if (inputValue.indexOf('+') !== 0) {
    nextValue = `+${inputValue}`
  }

  if (inputValue.indexOf('46') !== 1 && inputValue.indexOf('46') !== 0) {
    nextValue = `+46${inputValue}`
  }

  nextValue = `${nextValue.substring(0, 1)}${nextValue
    .substring(1)
    .replace(/\+/g, '')}`

  const result = /^\+?4?6?(?<zero>0)/.exec(nextValue)

  if (result?.[0]?.length && result?.[1]?.length) {
    const a = nextValue.substring(0, result[0].length - 1)
    const b = nextValue.substring(result[0].length)
    nextValue = `${a}${b}`
  }

  return nextValue
}

export function getCoverLetterName({
  firstName,
  lastName,
  company,
  acronym,
}: {
  firstName?: string
  lastName?: string
  company?: string
  acronym?: string
}): string {
  return [company, firstName, lastName, acronym].filter(Boolean).join('-')
}

export function acronymize(str?: string) {
  if (!str) return ''
  const parts = str.split(' ')
  return parts
    .map((part: string) => part.charAt(0))
    .join('')
    .toLocaleUpperCase()
}

export function getExperienceDateString(
  startDate: number,
  endDate: number | null,
): string {
  const range = getDateRangeString(startDate, endDate, unix => {
    const date = new Date(unix)
    const month = i18n.t(`common:${MONTH_ARRAY[date.getMonth()]}`).substr(0, 3)
    return `${month}-${date.getFullYear()}`
  })

  const period = endDate ? range : `${range} - ${i18n.t('common:ongoing')}`

  return period
}

export function getQueryString(
  query: string | string[] | undefined,
): string | undefined {
  return Array.isArray(query) ? query[0] : query
}
